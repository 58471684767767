import { template as template_2c8ff7b18fd444748b8a7092eed0ec8c } from "@ember/template-compiler";
const SidebarSectionMessage = template_2c8ff7b18fd444748b8a7092eed0ec8c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
