import { template as template_a3299d303ad7438584b0d6590254c672 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a3299d303ad7438584b0d6590254c672(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
